
const DEV_BASE_URL = 'ws://api.beecsgo.com/prod-ws';
// const DEV_BASE_URL = 'ws://192.168.1.11:8083';


export default {
  BASE_API: DEV_BASE_URL,
};
// const DEV_BASE_URL = 'ws://localhost:8082'
// const DEV_BASE_URL = 'ws://123.60.170.219:8082/ws';
// const DEV_BASE_URL = "ws://123.60.170.219:8081";
// const DEV_BASE_URL = "ws://192.168.1.39:8082";
// const DEV_BASE_URL = "ws://test.csgo800.com/prod-api";
// const DEV_BASE_URL = "ws://www.mkcsgo.com/prod-api";
// const DEV_BASE_URL = "ws://47.113.194.138:80/prod-api";